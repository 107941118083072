<template>
  <b-modal
    id="modal-cambiar-turno"
    size="custom-md-max"
    dialog-class="pt-5rem"
    header-class="bg-orange d-block"
    :visible="visible"
    hide-footer
    @change="$emit('change', $event)"
    @show="onOpenModal"
  >
    <template #modal-header>
      <div class="row" style="position: absolute; top: -38px">
        <div
          ref="containerTabs"
          class="col-sm-12 scrollable-x"
          @click="setScrollPosition($event)"
        >
          <div
            v-for="(item, index) in tabListMedicos"
            :key="item.key"
            class="btn"
            :class="getClassTab(item.key, index)"
            @click="changeTab(item)"
          >
            <b>Dr.:</b> {{item.medico}}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <h6 class="modal-title text-white" v-if="isReconsulta === false">
            <b>Paciente:</b> {{itemSeleccionado.cliente}}
          </h6>
          <h6 v-else class="modal-title text-white" >
            <b>Paciente:</b> {{itemSeleccionado.paciente}}
          </h6>
        </div>
        <div class="col-sm-6">
          <h6
            v-if="usedBy === 1"
            class="modal-title text-white pr-5"
          >
            <b>Fecha Atención:</b> {{getFechaAtencion}}
            <b class="pl-1">Hora:</b> {{getHoraAtencion}}
          </h6>
          <h6
            v-if="usedBy === 2"
            class="modal-title pr-5"
            style="color: #1b8920"
          >
            <b>Fecha Atendido: 30/01/2024</b>
          </h6>
        </div>
      </div>
    </template>
    <div class="row mx-4 mb-3">
      <div class="col-sm-3 pr-0">
        <button
          class="btn btn-block"
          :class="getClassTransferirButton"
          @click="onClickTransferirButton"
        >
          Transferir
        </button>
      </div>
      <div class="col-sm-9">
        <multiselect
          v-model="medicoSelected"
          :options="listaMedicos"
          :disabled="optionSelected != 1"
          @input="onSelectComboMedicos"
          placeholder="Seleccione un médico"
          selectedLabel=""
          track-by="id"
          label="nombre"
          select-label=""
          deselect-label="X"
        >
          <span slot="caret" v-if="!(medicoSelected===null)"></span>
          <span slot="noResult">Sin Resultados</span>
          <span slot="noOptions">Lista Vacia</span>
        </multiselect>
      </div>
    </div>
    <div class="row mx-4 mb-3" v-if="showButtonTurnos">
      <div class="offset-sm-6 col-sm-6">
        <button
          v-if="enableTurno1"
          class="btn py-1 mr-3 col-sm-5"
          :class="turno === 1? 'btn-success' : 'btn-secondary'"
          @click="selectTurno1"
        >
          Turno 1
        </button>
        <button
          v-if="enableTurno2"
          class="btn py-1 col-sm-5"
          :class="turno === 2? 'btn-success' : 'btn-secondary'"
          @click="selectTurno2"
        >Turno 2</button>
      </div>
    </div>
    <div class="row mx-4 mb-3">
      <div class="col-sm-3 pr-0">
        <button
          class="btn btn-block"
          :class="getClassCambiarFechaButton"
          @click="onClickCambiarFecha"
        >
          {{getTextForButtonFecha}}
        </button>
      </div>
      <div class="col-sm-3 pr-1">
        <date-picker
          class="col-sm-12 px-0"
          v-model="inputFecha"
          @input="onInputFecha"
          :disabled-date="disabledBeforeToday"
          :disabled="optionSelected === 0"
          :clearable="false"
          :editable="false"
          lang="es"
          format="DD/MM/YYYY"
          type="date"
          value-type="date"
        ></date-picker>
      </div>
      <div class="col-sm-3 pl-0 pr-1" v-if="tipoTurnoMedicoId === 1">
        <input
          type="text"
          class="form-control cursor-pointer"
          :value="horariosDisponibles"
          @click="isSeleccionManual=!isSeleccionManual"
          readonly="true"
          :disabled="optionSelected === 0"
        >
      </div>
      <div
        v-if="isSeleccionManual || tipoTurnoMedicoId === 2"
        class="col-sm-2 pl-0 pr-2"
      >
        <input
          v-model="horarioManualValue"
          type="time"
          class="form-control cursor-pointer"
          :disabled="optionSelected === 0"
          :style="{
            backgroundColor: optionSelected === 0
              ? '#e9ecef' : '',
              color: optionSelected === 0 ? '#6c757d' : ''
          }"
        >
      </div>
      </div>
      <div
        v-if="!isSeleccionManual && tipoTurnoMedicoId === 1"
        class="col-sm-3 pl-0"
      >
        <multiselect
          v-model="horarioSelected"
          :options="listaHorarios"
          :disabled="optionSelected === 0"
          placeholder=""
          selectedLabel=""
          label="hora"
          track-by="ticket"
          select-label=""
          deselect-label="X"
        >
          <span slot="caret" v-if="!(horarioSelected===null)"></span>
          <span slot="noResult">Sin Resultados</span>
          <span slot="noOptions">No trabaja</span>
          <template slot="singleLabel" slot-scope="props">
            <small>{{props.option.ticket}} - {{props.option.hora}}</small>
          </template>
          <template slot="option" slot-scope="props">
              <small v-if="formatOptionLabel(props.option.hora)"
                class="yellow-border"> {{ props.option.ticket }} - {{ props.option.hora }}
              </small>
              <small v-else>{{ props.option.ticket }} - {{ props.option.hora }}</small>
          </template>
        </multiselect>
      </div>
    <div class="row mt-3">
      <div class="col-sm-12 text-right">
        <button
          class="btn btn-light-gray mr-2"
          @click="hideModal"
        >
          Cancelar
        </button>
        <button
          v-if="optionSelected != 0"
          class="btn btn-success"
          @click="guardarCambios"
          :disabled="disableSaveButton"
        >
          Guardar
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import { disabledBeforeToday } from '@/utils/helpers';
import Multiselect from 'vue-multiselect';
import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';

export default {
  name: 'ModalCambiarTurno',
  components: {
    Multiselect,
    DatePicker,
  },
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    itemSeleccionado: {
      type: Object,
      required: true,
    },
    isReconsulta: {
      type: Boolean,
    },
    usedBy: {
      type: Number,
      default: 1,
      validator(value) {
        // 1: ventas, 2: reconsulta
        return [1, 2].includes(parseInt(value, 10));
      },
    },
  },
  data() {
    return {
      turnoId: null,
      inputFecha: null,
      horarioSelected: null,
      listaHorarios: [],
      medicoSelected: null,
      listaMedicos: [],
      optionSelected: 0,
      tabListMedicos: [],
      medicoTurnoActive: null,
      turno: 0,
      isSeleccionManual: false,
      horarioManualValue: '',
      tipoTurnoMedicoId: 0,
      enableTurno1: false,
      enableTurno2: false,
      disableSaveButton: false,
    };
  },
  methods: {
    disabledBeforeToday,
    async onOpenModal() {
      await this.getMedicosTurnos();
      await this.getConfiguracionTurno(this.medicoTurnoActive.medico_id);
      this.turno = this.medicoTurnoActive.numero_turno;
      this.getTurnos(this.medicoTurnoActive.medico_id);
    },
    async getConfiguracionTurno(medicoId) {
      try {
        const REQUEST = {
          medico_id: medicoId,
        };
        const { data: { data: RESPONSE } } = await axios.get('/clinic/invoicing/turno/configuracion', {
          params: REQUEST,
        });
        const TURNOS_MEDICO = RESPONSE.turno_medico;
        if (TURNOS_MEDICO.length > 0) {
          const HAS_TURNO_1 = TURNOS_MEDICO.some((el) => el.numero_turno === 1);
          const HAS_TURNO_2 = TURNOS_MEDICO.some((el) => el.numero_turno === 2);
          this.enableTurno1 = HAS_TURNO_1;
          this.enableTurno2 = HAS_TURNO_2;
          if (HAS_TURNO_1 && !HAS_TURNO_2) {
            this.turno = 1;
          }
          if (!HAS_TURNO_1 && HAS_TURNO_2) {
            this.turno = 2;
          }
        } else {
          this.enableTurno1 = false;
          this.enableTurno2 = false;
        }
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    formatOptionLabel(option) {
      if (moment(option, 'HH:mm:ss').format('HH:mm') === this.horarioManualValue) {
        return true;
      }
      return false;
    },
    async getMedicosTurnos() {
      try {
        const REQUEST = {
          is_reconsulta: this.isReconsulta,
        };
        const VENTA_ID = this.isReconsulta === false
          ? this.itemSeleccionado.id : this.itemSeleccionado.venta_id;

        const { data: { data: RESPONSE } } = await axios.get(`/clinic/invoicing/turno/details_turno/${VENTA_ID}`, {
          params: REQUEST,
        });
        this.listaMedicos = RESPONSE.medicos;
        this.tabListMedicos = RESPONSE.medico_turnos.map((el, index) => ({ key: index, ...el }));
        [this.medicoTurnoActive] = this.tabListMedicos;
        this.inputFecha = new Date(moment(this.medicoTurnoActive.fecha));
        this.horarioManualValue = this.medicoTurnoActive.hora;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    cargarListaHorarios(lista) {
      const FECHA_SELECCIONADA = moment(this.inputFecha).format('YYYY-MM-DD');
      const FECHA_ACTUAL = moment(new Date()).format('YYYY-MM-DD');
      const HORA_ACTUAL = moment(new Date()).format('HH:mm');
      const TEMP_LIST = [];

      lista.forEach((el) => {
        let estado = false;
        let { hora } = el;

        if (hora !== 'NO Disponible') {
          // Extraer la parte de la hora y convertirla al formato deseado
          const horaMatch = hora.match(/^(\d{2}:\d{2}:\d{2})/);
          if (horaMatch) {
            hora = moment(horaMatch[1], 'HH:mm:ss').format('HH:mm') + hora.substring(8);
          }
          if (FECHA_SELECCIONADA < FECHA_ACTUAL) {
            estado = true;
          } else if (HORA_ACTUAL > moment(horaMatch[1], 'HH:mm:ss').format('HH:mm') && FECHA_SELECCIONADA === FECHA_ACTUAL) {
            estado = true;
          } else if (moment(horaMatch[1], 'HH:mm:ss').format('HH:mm') === this.horarioManualValue) {
            estado = true;
          }
        } else {
          estado = true;
        }
        if (hora.includes(' - ')) {
          estado = true;
        }
        const ITEM = {
          $isDisabled: estado,
          ticket: el.ticket,
          hora,
        };
        TEMP_LIST.push(ITEM);
      });
      this.listaHorarios = TEMP_LIST;
    },
    async getTurnos(medicoId) {
      try {
        const REQUEST = {
          medico_id: medicoId,
          fecha: moment(this.inputFecha).format('YYYY-MM-DD'),
          turno: this.turno,
        };
        const { data: { data: RESPONSE } } = await axios.get('/clinic/invoicing/turno/', {
          params: REQUEST,
        });
        this.turnoId = RESPONSE.turno_medico?.id ?? null;
        const TURNOS = RESPONSE.turno_medico?.turnos ?? [];
        this.tipoTurnoMedicoId = RESPONSE.tipo_turno?.tipo_turno_medico_id ?? 0;
        this.cargarListaHorarios(TURNOS);
      } catch (error) {
        const FALLO_VALIDACION = 422;
        if (error.response.status !== FALLO_VALIDACION) {
          util.showNotify(error.response.data.message, 'error');
        } else {
          Object.entries(error.response.data.data).forEach(([, mensajes]) => {
            mensajes.forEach((texto) => util.showNotify(texto, 'error'));
          });
        }
      }
    },
    async onSelectComboMedicos() {
      this.turno = 1;
      if (this.medicoSelected === null) {
        await this.getConfiguracionTurno(this.medicoTurnoActive.medico_id);
        await this.getTurnos(this.medicoTurnoActive.medico_id);
      } else {
        await this.getConfiguracionTurno(this.medicoSelected.id);
        await this.getTurnos(this.medicoSelected.id);
      }
    },
    async guardarCambios() {
      if (this.tipoTurnoMedicoId !== 2) {
        if (this.isSeleccionManual === false && this.horarioSelected === null) {
          util.showNotify('Seleccione un horario', 'warn');
          return;
        }
        if (this.isSeleccionManual && this.horarioManualValue === '') {
          util.showNotify('Escriba la hora', 'warn');
          return;
        }
      }
      const ID = this.medicoTurnoActive.venta_detalle_turno_id;
      let numeroTicket = null;
      let horaTurno = null;
      let medicoid = null;

      if (this.optionSelected === 1 /* viene de Transferir */) {
        medicoid = this.medicoSelected.id;
      }
      if (this.optionSelected === 2 /* es el mismo del tab activo */) {
        medicoid = this.medicoTurnoActive.medico_id;
      }
      if (this.tipoTurnoMedicoId === 1) {
        if (this.isSeleccionManual) {
          numeroTicket = null;
          horaTurno = this.horarioManualValue;
        } else {
          horaTurno = this.horarioSelected?.hora ?? null;
          numeroTicket = this.horarioSelected?.ticket ?? null;
        }
      }
      if (this.tipoTurnoMedicoId === 2) {
        horaTurno = this.horarioManualValue;
      }
      if (this.isReconsulta === false) {
        const REQUEST = {
          numero_ticket: numeroTicket,
          turno_medico_id: this.turnoId,
          fecha: moment(this.inputFecha).format('YYYY-MM-DD'),
          hora: horaTurno,
          is_turno_manual: this.isSeleccionManual,
          medico_id: medicoid,
          venta_detalle_turno_id: this.itemSeleccionado.venta_detalle_turno_id,
          is_reconsulta: this.isReconsulta,
        };

        this.disableSaveButton = true;
        try {
          const RESPONSE = await axios.put(`/clinic/invoicing/turno/update_details_turno/${ID}`, REQUEST);
          util.showNotify(RESPONSE.data.message, 'success');
          this.$emit('onUpdateTurno', true);
        } catch (error) {
          util.showNotify(error.response.data.message, 'error');
        } finally {
          this.disableSaveButton = false;
        }
        this.hideModal();
      } else {
        const REQUESTRECONSULTA = {
          numero_ticket: numeroTicket,
          turno_medico_id: this.turnoId,
          fecha_turno: moment(this.inputFecha).format('YYYY-MM-DD'),
          hora_turno: horaTurno,
          is_turno_manual: this.isSeleccionManual,
          venta_detalle_turno_id: this.itemSeleccionado.venta_detalle_turno_id,
        };

        this.disableSaveButton = true;
        try {
          const RESPONSE = await axios.post(`clinic/invoicing/reconsulta/${this.itemSeleccionado.consulta_id}`, REQUESTRECONSULTA);
          util.showNotify(RESPONSE.data.message, 'success');
        } catch (error) {
          util.showNotify(error.response.data.message, 'error');
        } finally {
          this.disableSaveButton = false;
        }
        this.hideModal();
      }
    },
    onInputFecha() {
      this.horarioSelected = null;
      this.horarioManualValue = '';
      this.isSeleccionManual = false;
      this.listaHorarios = [];
      let medicoId = this.medicoTurnoActive.medico_id;
      if (this.inputFecha === null) {
        return;
      }
      if (this.medicoSelected) {
        medicoId = this.medicoSelected.id;
      }
      this.getTurnos(medicoId);
    },
    hideModal() {
      this.$bvModal.hide('modal-cambiar-turno');
    },
    onClickTransferirButton() {
      this.optionSelected = 1;
      /* this.inputFecha = null; */
      this.isSeleccionManual = false;
      this.horarioSelected = null;
      this.horarioManualValue = '';
      this.listaHorarios = [];
    },
    async onClickCambiarFecha() {
      this.optionSelected = 2;
      if (this.medicoSelected) {
        this.medicoSelected = null;
        await this.getConfiguracionTurno(this.medicoTurnoActive.medico_id);
        await this.getTurnos(this.medicoTurnoActive.medico_id);
      }
    },
    changeTab(item) {
      if (this.medicoTurnoActive.key === item.key) {
        return;
      }
      this.optionSelected = 0;
      this.medicoTurnoActive = item;
      this.medicoSelected = null;
      this.turno = this.medicoTurnoActive.numero_turno;
      this.inputFecha = new Date(moment(this.medicoTurnoActive.fecha));
      this.getTurnos(this.medicoTurnoActive.medico_id);
    },
    getClassTab(key, index) {
      let result = '';
      if (this.getKeyMedicoTurnoSelected === key) {
        result = result.concat(' ', 'btn-light-gray');
      } else {
        result = result.concat(' ', 'btn-light');
      }

      if (this.tabListMedicos.length === 1) {
        result = result.concat(' ', 'custom-tab-rounded-top');
      } else if (index === 0) {
        result = result.concat(' ', 'custom-tab-rounded-left');
      } else if (index === this.tabListMedicos.length - 1) {
        result = result.concat(' ', 'custom-tab-rounded-right');
      } else {
        result = result.concat(' ', 'rounded-0');
      }

      return result.trim();
    },
    /* getPositionScroll(event) {
      console.log(event.target.scrollLeft);
    }, */
    async selectTurno1() {
      if (this.turno === 1) {
        return;
      }
      this.turno = 1;
      this.horarioSelected = null;
      let medicoId = this.medicoTurnoActive.medico_id;
      if (this.medicoSelected) {
        medicoId = this.medicoSelected.id;
      }
      await this.getTurnos(medicoId);
    },
    async selectTurno2() {
      this.turno = 2;
      this.horarioSelected = null;
      let medicoId = this.medicoTurnoActive.medico_id;
      if (this.medicoSelected) {
        medicoId = this.medicoSelected.id;
      }
      await this.getTurnos(medicoId);
    },
    setScrollPosition(event) {
      this.$refs.containerTabs.scrollLeft = event.layerX - 14;
    },
  },
  computed: {
    getClassTransferirButton() {
      if (this.optionSelected === 1) {
        return 'btn-success';
      }
      return 'btn-light-gray';
    },
    horariosDisponibles() {
      if (this.isSeleccionManual) {
        return 'Abierto';
      }
      const LISTA_DISPONIBLES = [];
      this.listaHorarios.forEach((el) => {
        if (el.$isDisabled === false) {
          LISTA_DISPONIBLES.push(el);
        }
      });
      return `Disponible: ${LISTA_DISPONIBLES.length}`;
    },
    getClassCambiarFechaButton() {
      if (this.optionSelected === 2) {
        return 'btn-success';
      }
      return 'btn-light-gray';
    },
    getKeyMedicoTurnoSelected() {
      return this.medicoTurnoActive?.key;
    },
    getFechaAtencion() {
      if (this.medicoTurnoActive != null) {
        return moment(this.medicoTurnoActive.fecha).format('DD/MM/YYYY');
      }
      return '';
    },
    getHoraAtencion() {
      return this.medicoTurnoActive?.hora ?? '';
    },
    showButtonTurnos() {
      return (
        this.optionSelected === 2
        || (this.optionSelected === 1 && this.medicoSelected != null)
      );
    },
    getTextForButtonFecha() {
      if (this.usedBy === 2) {
        return 'Programar Fecha';
      }
      return 'Cambiar Fecha';
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.custom-tab-rounded-top {
  border-top-left-radius: 0.625rem !important;
  border-top-right-radius: 0.625rem !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.custom-tab-rounded-left {
  border-top-left-radius: 0.625rem !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.custom-tab-rounded-right {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0.625rem !important;
  border-bottom-right-radius: 0 !important;
}
.scrollable-x {
  display: flex;
  width: 686px;
  overflow-x: scroll;
  /* scrollbar-width: none; */
  border-top-right-radius: 0.625rem !important;
}
.scrollable-x::-webkit-scrollbar {
  /* display: none; */
  background-color: transparent;
  height: 7px;
}
.scrollable-x::-webkit-scrollbar-thumb {
  background: #8f8b8b;
  border-radius: 10px;
}
.yellow-border {
  border: 3px solid yellow;
  padding: 11px;
  margin: -10px -10px -5px -10px;
  display: block;
}

</style>
